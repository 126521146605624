

function currentReducer(state, action) {
    switch (action.type) {
        case 'RECEIVE_PRINCIPAL_DETAILS':
            if(action.principal) {
                return {...action.principal};
            } else {
                return null;
            }
        default:
            return state;
    }
}

const principalReducer = ({details}, action) => ({
    details: currentReducer(details, action),
})

export default principalReducer;