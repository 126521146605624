import {toggleArray} from "../../utils/arrays";

function listReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_MESSAGES_LIST':
            return [...action.messages];
        default:
            return state;
    }
}

function selectReducer(state, action) {
    switch (action.type) {
        case 'SELECT_MESSAGES':
            return [...action.messages];
        case 'TOGGLE_MESSAGE':
            return toggleArray(state, action.message);
        case 'RECEIVE_MESSAGES_LIST':
            return [];
        default:
            return state;
    }
}

function detailsReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_MESSAGE_DETAILS':
            if(action.message) {
                return {...action.message};
            } else {
                return {};
            }
        default:
            return state;
    }
}

const messagesReducer = ({group, list, select, details}, action) => ({
    list: listReducer(list, action),
    select: selectReducer(select, action),
    details: detailsReducer(details, action),
})

export default messagesReducer;