
function listReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_OLDS_LIST':
            return [...action.olds];
        default:
            return state;
    }
}

function detailsReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_OLD_DETAILS':
            if(action.old) {
                return {...action.old};
            } else {
                return {};
            }
        default:
            return state;
    }
}

const oldsReducer = ({list, current, details}, action) => ({
    list: listReducer(list, action),
    details: detailsReducer(details, action),
})

export default oldsReducer;