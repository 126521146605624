import createTheme from '@material-ui/core/styles/createTheme';
import createSpacing from "@material-ui/core/styles/createSpacing";
import color from "color";

const spacing = createSpacing();

const LMMPrimaryLight = '#5289bc';
const LMMPrimaryMain = '#175c8c';
const LMMPrimaryDark = '#0f3a59';

const LMMSecondaryLight = '#db4f40';
const LMMSecondaryMain = '#a31818';
const LMMSecondaryDark = '#6d0000';

const LMMOrangeFront = '#ffffff';
const LMMOrangeBack = '#cf8f3f';

const theme = createTheme({
	palette: {
		primary: {
			light: LMMPrimaryLight,
			main: LMMPrimaryMain,
			dark: LMMPrimaryDark
		},
		secondary: {
			light: LMMSecondaryLight,
			main: LMMSecondaryMain,
			dark: LMMSecondaryDark
		},
		background: {
			'default': '#dfdfdf'
		}
	},
	overrides: {
		MuiTab: {
			textColorPrimary: {
				color: LMMPrimaryDark,
			},
			wrapper: {
				flexDirection: 'row'
			}
		},
		MuiBadge: {
			root: {
				margin: spacing(),
				paddingRight: spacing()
			},
			colorSecondary: {
				color: LMMOrangeFront,
				backgroundColor: LMMOrangeBack
			}
		},
		MuiTabs: {
			root: {
				backgroundColor: LMMOrangeFront,
			},
			indicator: {
				backgroundColor: LMMOrangeBack
			}			
		},
		MuiDialog: {
			paper: {
				margin: spacing(3),
				maxHeight: 'calc(100% - 48px)',
			},
			paperScrollPaper: {
				maxHeight: 'calc(100% - 48px)',
			}
		},
		MuiGrid: {
			container: {
				padding: spacing(2),
			},
			item: {
				alignSelf: 'center '
			}
		},
		MuiCardContent: {
			root: {
				'&:last-child': {
					paddingBottom: spacing()
				}
			}
		},
		MuiListItem: {
			root: {
				minWidth: 220,
			},
		},
		MuiInputBase: {
			root: {
				color: LMMPrimaryDark,
				'&:before': {
					borderBottomColor: [color(LMMPrimaryDark).alpha(0.2).string(), '!important'],
				},
				'&:after': {
					borderBottomColor: [color(LMMPrimaryDark).alpha(0.2).string(), '!important'],
				},
				'&:hover:before': {
					borderBottomColor: [color(LMMPrimaryDark).alpha(0.5).string(), '!important'],
				},
				'&:hover:after': {
					borderBottomColor: [color(LMMPrimaryDark).alpha(0.5).string(), '!important'],
				},
			},
			colorSecondary: {
				color: LMMSecondaryMain,
				'&:before': {
					borderBottomColor: [color(LMMSecondaryMain).alpha(0.2).string(), '!important'],
				},
				'&:after': {
					borderBottomColor: [color(LMMSecondaryMain).alpha(0.2).string(), '!important'],
				},
				'&:hover:before': {
					borderBottomColor: [color(LMMSecondaryMain).alpha(0.5).string(), '!important'],
				},
				'&:hover:after': {
					borderBottomColor: [color(LMMSecondaryMain).alpha(0.5).string(), '!important'],
				},
			}
		},
		MuiTypography: {
			colorPrimary: {
				color: LMMPrimaryDark,
			},
			colorSecondary: {
				color: LMMSecondaryMain,
			}
		},
		MuiIconButton: {
			colorPrimary: {
				color: LMMPrimaryDark
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: 13
			}
		},
		MuiAccordionDetails: {
			root: {
				background: '#f7f7f7',
				borderTop: '1px solid #dfdfdf'
			}
		}
	},
	props: {
		MuiGrid: {
			spacing: 2
		},
		MuiInput: {
			disableUnderline: false
		},
		MuiDialog: {
			disableEnforceFocus: true
		}
	},
	typography: {
	    useNextVariants: true,
	},
});

export default theme;