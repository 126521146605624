import {toggleArray} from "../../utils/arrays";

function groupReducer(state, action) {
    switch(action.type) {
        case 'CHOOSE_CARD_GROUP':
            return action.group + "";
        default:
            return state;
    }
}

function listReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_CARDS_LIST':
            return [...action.cards];
        default:
            return state;
    }
}

function selectReducer(state, action) {
    switch (action.type) {
        case 'SELECT_CARDS':
            return [...action.cards];
        case 'TOGGLE_CARD':
            return toggleArray(state, action.card);
        case 'RECEIVE_CARDS_LIST':
            return [];
        case 'RECEIVE_OLDS_LIST':
            return [];
        default:
            return state;
    }
}

function detailsReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_CARD_DETAILS':
            if(action.card) {
                return {...action.card};
            } else {
                return {};
            }
        default:
            return state;
    }
}

const cardsReducer = ({group, list, select, details}, action) => ({
    group: groupReducer(group, action),
    list: listReducer(list, action),
    select: selectReducer(select, action),
    details: detailsReducer(details, action),
})

export default cardsReducer;