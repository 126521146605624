
function listReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_STATS_LIST':
            return [...action.stats];
        default:
            return state;
    }
}

function detailsReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_STAT_DETAILS':
            if(action.stat) {
                return {...action.stat};
            } else {
                return {};
            }
        default:
            return state;
    }
}

const statsReducer = ({list, details}, action) => ({
    list: listReducer(list, action),
    details: detailsReducer(details, action),
})

export default statsReducer;