import messagesReducer from "./messages";
import statsReducer from "./stats";
import logsReducer from "./logs";

function groupReducer(state, action) {
    switch(action.type) {
        case 'CHOOSE_ADMIN_GROUP':
            return action.group + "";
        default:
            return state;
    }
}

const adminReducer = ({group, messages, stats, logs}, action) => {
    const result = {
        group: groupReducer(group, action),
        messages: messagesReducer(messages, action),
        stats: statsReducer(stats, action),
        logs: logsReducer(logs, action)
    }
    return result
};

export default adminReducer;