import cardsReducer from './cards';
import principalReducer from "./principal";
import oldsReducer from "./olds";
import adminReducer from "./admin";

const appReducer = ({principal, cards, olds, admin}, action) => {
   const result = {
      principal: principalReducer(principal, action),
      cards: cardsReducer(cards, action),
      olds: oldsReducer(olds, action),
      admin: adminReducer(admin, action)
   };
   if(action.type) {
      console.group(action.type);
      console.debug("dispatching", action);
      console.debug("next state", result);
      console.groupEnd();
   }
   return result;
};

export default appReducer;
