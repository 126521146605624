
export function toggleArray(array, value) {
    if(array.indexOf(value) < 0) {
        return [value, ...array];
    } else {
        return array.filter(function (item) {
            return item !== value;
        })
    }
}

export function diffArray(arr1, arr2) {
    return arr2.filter(item => {
        return arr1.indexOf(item) < 0;
    });
}

export function ensureSize(array, length) {
    let array2 = array || [];
    if(array2.length !== length) {
        array2.length = length;
    }
    return array2;
}

export function uniqueNumbers(array) {
    return array.reduce(function (prev, curr) {
        if(curr && prev.indexOf(curr) < 0) {
            return [curr, ...prev];
        } else {
            return prev;
        }
    }, []).sort((a, b) => {
        return (a * 1) - (b * 1);
    });
}

export function range(start, stop, step) {
    const step2 = step || 1;
    return Array.from(
        {length: (stop - start) / step2 + 1},
        (_, i) => start + (i * step2)
    );
}