
function listReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_LOGS_LIST':
            return [...action.logs];
        default:
            return state;
    }
}

function detailsReducer(state, action) {
    switch(action.type) {
        case 'RECEIVE_LOGS_DETAILS':
            if(action.log) {
                return {...action.log};
            } else {
                return {};
            }
        default:
            return state;
    }
}

const logsReducer = ({list, details}, action) => ({
    list: listReducer(list, action),
    details: detailsReducer(details, action),
})

export default logsReducer;