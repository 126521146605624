//import "./bootstrap";
import './i18n';

import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import ReactDOM from 'react-dom';

import {StateProvider} from "./state";
import appReducer from './state/reducers';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import LMMTheme from './theme.js'
import {SnackbarProvider} from "notistack";
import {CookiesProvider} from "react-cookie";
import {isIE} from 'react-device-detect';

import './index.css';

import moment from "moment";
import 'moment/locale/pl';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const LoginContainer = lazy(() => import("./features/login/containers/LoginContainer"));
const ShutdownContainer = lazy(() => import("./features/shutdown/containers/ShutdownContainer"))
const RevertContainer = lazy(() => import("./features/revert/containers/RevertContainer"))
const MainContainer = lazy(() => import("./features/main/containers/MainContainer"));

moment.locale('pl');

//import registerServiceWorker from './registerServiceWorker';

const App = () => {

	const initialState = {
		principal: {
			// Single principal
			details: {},
		},
		cards: {
			// Multiple cards
			group: "",
			list: [],
			select: [],
			// Single card
			details: [],
		},
		olds: {
			// Multiple old cards
			list: [],
			// Single card
			details: [],
		},
		admin: {
			group: "",
			messages: {
				// Multiple messages
				list: [],
				select: [],
				// Single message
				details: []
			},
			stats: {
				// Multiple statistics
				list: [],
				// Single statistic
				details: []
			},
			logs: {
				// Multiple logs
				list: [],
				// Single day logs
				details: []
			}
		},
	};

	const Loading = () =>
		<Backdrop open={true}>
			<CircularProgress color={"inherit"}/>
		</Backdrop>

	if(isIE) {
		return (
			<MuiThemeProvider theme={LMMTheme}>
				<CssBaseline/>
				<Dialog open={true} fullWidth={false} maxWidth={"md"}>
					<DialogContent>
						<DialogContentText>
							Niestety aplikacja nie będzie działała poprawnie z Twoją przeglądarką.<br/>
							Wykorzystujemy możliwości i bezpieczeństwo współczesnych przeglądarek.<br/>
							Sugerujemy użycie Microsoft Edge, Google Chrome lub Mozilla firefox.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button color="secondary" variant={"contained"} onClick={() => {window.location = 'http://npr.pl'}}>Zamknij</Button>
					</DialogActions>
				</Dialog>
			</MuiThemeProvider>
		)
	}

	return (
		<MuiThemeProvider theme={LMMTheme}>
			<CssBaseline/>
			<BrowserRouter>
				<StateProvider initialState={initialState} reducer={appReducer}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<SnackbarProvider maxSnack={5} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} preventDuplicate={true}>
							<CookiesProvider>
								<main style={{flexGrow: 1, display: 'flex', height: '100vh'}}>
									<Suspense fallback={<Loading/>}>
										<Switch>
											<Route path={"/start"} component={LoginContainer}/>
											<Route path={"/shutdown/:key([0-9a-fA-F-]+)"} component={ShutdownContainer}/>
											<Route path={"/revert/:key([0-9a-fA-F-]+)"} component={RevertContainer}/>
											<Route path={"/"} component={MainContainer}/>
										</Switch>
									</Suspense>
								</main>
							</CookiesProvider>
						</SnackbarProvider>
					</MuiPickersUtilsProvider>
				</StateProvider>
			</BrowserRouter>
		</MuiThemeProvider>
	)
}

ReactDOM.render(<App/>, document.getElementById('root'));
//registerServiceWorker();
